import React from 'react'

import RouteButton from '../RouteButton/RouteButton'
import pageImage from './dinosaur.png'

import './PageNotFound.scss'

interface Props {
  siteName: string
  pageName: string
}

export default class PageNotFound extends React.Component<Props> {
  public componentWillMount() {
    if(typeof window === 'undefined') return

    document.title = `${this.props.pageName} | ${this.props.siteName}`
  }

  public render() {
    return (
      <div className="page-not-found page-content">
        <img className="page-not-found__image" src={pageImage} alt=""/>
        <p>Looks like you're trying to access a prehistoric page.</p>
        <RouteButton to="/">Return Home</RouteButton>
      </div>
    )
  }
}
