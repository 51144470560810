import React from 'react'

import MainLayout from '../layouts/MainLayout'
import PageNotFound from '../components/PageNotFound/PageNotFound'
import { COMPANY_NAME } from '../constants'

const ErrorPage: React.FunctionComponent = () => (
  <MainLayout title={`${COMPANY_NAME} – Page not Found`}>
    <PageNotFound siteName="Sonocent" pageName="Page Not Found" />
  </MainLayout>
)

export default ErrorPage
